/*import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/src/locale/pl.ts'
import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)
Vue.use(Vuetify, {
    iconfont: 'fa',
    locales: {pl},
    current: 'pl'
})
*/

import Vue from 'vue';
import pl from 'vuetify/src/locale/pl.ts'
import Datetime from 'vue-datetime'
import Vuetify from 'vuetify/lib/framework';

Vue.use(Datetime)
Vue.use(Vuetify, {
    iconfont: 'fa',
    locales: {pl},
    current: 'pl'
})


export default new Vuetify({
});

