const DEFAULT_URL = "http://localhost:5003/graphql";

let now = Date.now();
const uid = () => `${(now++).toString(16)}`;

let graphqlUri = null;

const fetchGraphqlUri = async () => {
  let configJson = await fetch(`/config.json?${uid()}`);
  let config = await configJson.json();

  return config.graphqlUrl || DEFAULT_URL;
}

export const customFetch = async (uri, options) => {
  if (graphqlUri === null) {
    graphqlUri = await fetchGraphqlUri();
  }
  return fetch(graphqlUri, options);
};
