import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import {createProvider} from './vue-apollo'
import router from './router'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VeeValidate from 'vee-validate'
import VuetifyConfirm from 'vuetify-confirm'
// import Vuelidate from 'vuelidate'

moment.locale('pl');
Vue.use(VueMoment, {
    moment,
})
// Vue.use(Vuelidate)

Vue.use(VeeValidate)

Vue.config.productionTip = false

Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: 'Accept',
    buttonFalseText: 'Discard',
    color: 'warning',
    icon: 'fa-skull-crossbones',
    title: 'Warning',
    width: 350,
    property: '$confirm'
})

new Vue({
    apolloProvider: createProvider(),
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
