import { AUTH_TOKEN } from '../vue-apollo'
import jwt_decode from 'jwt-decode'

const getTokenPayload = () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (!token) {
        return false;
    }

    return jwt_decode(token);
}

export const isInRole = (role) => {
    const payload = getTokenPayload();
    if (!payload) {
        return false;
    }

    return payload.role.toLowerCase() === role.toLowerCase();
}

export const isLoggedIn = () => {
    const payload = getTokenPayload();
    if (!payload) {
        return false;
    }

    return new Date(payload.exp * 1000) > new Date();
};