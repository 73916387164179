import Vue from 'vue'
import Router from 'vue-router'
import { isLoggedIn, isInRole } from './services/authService'

Vue.use(Router)

const requireAuth = (_to, _from, next) => {
    if (!isLoggedIn()) {
        next('login');
        return;
    }
    next();
};

const isAdmin = (_to, _from, next) => {
    if (!isLoggedIn() || !isInRole('admin')) {
        next('/login');
        return;
    }
    
    next();
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/release/list'
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('./components/pages/Register.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./components/pages/Login.vue')
        },
        {
            path: '/users/list',
            name: 'usersList',
            component: () => import('./components/pages/User/UserList.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/user/:userId/setPassword',
            name: 'setUserPassword',
            component: () => import('./components/pages/User/SetUserPassword.vue'),
            beforeEnter: requireAuth,
            props: (router) => {
                return {
                    userId: router.params.userId,
                };
            }
        },
        {
            path: '/user/changePassword',
            name: 'changePassword',
            component: () => import('./components/pages/User/ChangePassword.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/release/list',
            name: 'releasesList',
            component: () => import('./components/pages/Release/ListReleases.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/release/add',
            name: 'releaseAdd',
            component: () => import('./components/pages/Release/AddRelease.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/release/diff/:baseId/:targetId',
            name: 'releaseDiff',
            component: () => import('./components/pages/Release/DiffReleases.vue'),
            beforeEnter: requireAuth,
            props: (router) => {
                return {
                    baseId: router.params.baseId,
                    targetId: router.params.targetId,
                };
            }
        },
        {
            path: '/service/list',
            name: 'servicesList',
            component: () => import('./components/pages/Service/ListServices.vue'),
            beforeEnter: requireAuth
        },
        {
            path: '/service/add',
            name: 'serviceAdd',
            component: () => import('./components/pages/Service/AddService.vue'),
            beforeEnter: isAdmin
        }
    ]
})
